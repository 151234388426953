// audio files
import podcast_into from "./The Thomas Smith Podcast (Ep. 1).mp3";

// audio thumbnails
import podcast_cover from "./the_thomas_smith_podcast.png";

export const tracks = [
  {
    title: "Welcome to The Thomas Smith Podcast",
    src: podcast_into,
    author: "Thomas Smith",
    thumbnail: podcast_cover,
  },
];
